import { get, post } from '@shared/http'
import URL from './url'
import { objValuesHandler } from '@shared/util'

const SETTLEMENT_API = {
  receive: (params) => post(URL.receive, params),
  stockOrderList: (params) => get(URL.stockOrderList, params),
  stockOrderDetail: (id) => get(URL.stockOrderDetail(id)),
  stockOrderAdd: (params) => post(URL.stockOrderAdd, params),
  supplier: () => get(URL.supplier),
  skuBySupplier: (params) => get(URL.skuBySupplier, params),
  wastageCreate: (params) => post(URL.wastageCreate, params),
  wastagePage: (params) => get(URL.wastagePage, objValuesHandler(params)),
  wastageDetail: (id) => get(URL.wastageDetail(id)),

  inventoryPage: (params) => get(URL.inventoryPage, params),

  inventorySerialPage: (params) => get(URL.inventorySerialPage, objValuesHandler(params))
}

export default SETTLEMENT_API
