const wmsURL = '/srm-wms-service/' // 仓储服务
const inventoryURL = '/srm-inventory-service/' // 库存服务
const poiURL = '/srm-inventory-poi/' // 导出服务

export default {
  receive: wmsURL + 'base-board/receive', // 收货
  stockOrderList: wmsURL + 'in-stock-order/page', // 入库单列表
  stockOrderDetail: (orderId) => wmsURL + `in-stock-order/detail/${orderId}`, // 入库单详情
  stockOrderAdd: wmsURL + 'in-stock-order', // 新增入库单
  supplier: wmsURL + 'base-board/supplier', // 地板供应商下拉
  skuBySupplier: wmsURL + 'base-board/supplier/sku/page', // 新增入库单时分页获取sku信息
  wastageCreate: wmsURL + 'wastage', // 损耗申请
  wastagePage: wmsURL + 'wastage/page', // 损耗列表
  wastageDetail: id => wmsURL + `wastage/${id}`, // 损耗详情

  inventoryPage: inventoryURL + 'inventory/page', // 供应商库存分页
  inventorySerialPage: inventoryURL + 'inventory/serial/page', // 供应商库存流水分页

  inventoryExport: poiURL + 'inventory/export', // 供应商库存导出
  inventorySerialExport: poiURL + 'inventory/serial/export' // 供应商库存流水导出

}
