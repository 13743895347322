<template>
  <SlDialog
    title="底版报损"
    :visible.sync="show"
    :width="dialogWidth"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :lock-scroll="false"
    :append-to-body="true"
    :before-close="close"
    @cancel="close"
    @closed="closed"
  >
    <el-form ref="form" :model="tableForm">
      <SlTableInfo
        :isEmbedTable="true"
        :tableData="tableForm.wastageBatch"
        :columns="columns"
        headerAlign="left"
        max-height="360"
      >
        <template #wastageQuantity="{ row, index }">
          <template>
            <el-form-item
              label-width="0px"
              :prop="'wastageBatch.' + index + '.wastageQuantity'"
              :rules="rules.wastageQuantity"
            >
              <el-input
                v-model="row.wastageQuantity"
                v-slFormatNumber="numberFormat(row.available)"
                placeholder=">0"
              ></el-input>
            </el-form-item>
          </template>
        </template>
      </SlTableInfo>

      <el-form-item
        label="损耗原因："
        label-width="110px"
        prop="wastageReason"
        :rules="rules.wastageReason"
        style="margin-top: 16px;"
      >
        <el-input
          maxlength="50"
          show-word-limit
          type="textarea"
          v-model="tableForm.wastageReason"
          rows="3"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #bottom>
      <el-button @click="close">{{$t('button.cancelText')}}</el-button>
      <el-button
        type="primary"
        :loading="handleLoading"
        @click="handleSubmit"
      >{{$t('button.enterText')}}</el-button>
    </template>
  </SlDialog>
</template>
<script>
import OemInventory from '@api/oemInventory'

export default {
  name: 'InventoryDialog',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    // 考虑为负数的情况-待处理
    numberFormat () {
      return function (max) {
        return { type: 'integer', max, compareLength: false, includeZero: false }
      }
    }
  },
  data () {
    return {
      handleLoading: false,
      dialogWidth: '60%',
      columns: [
        {
          name: 'skuCode',
          label: '底版SKU'
        }, {
          name: 'skuImage',
          label: '图片',
          isImage: true,
          data: {
            imgSize: '6rem'
          }
        }, {
          name: 'productName',
          label: '商品名称'
        }, {
          name: 'available',
          label: '可用库存'
        }, {
          name: 'wastageQuantity',
          label: '损耗数量'
        }
      ],
      tableForm: {
        wastageBatch: [],
        wastageReason: ''
      },
      rules: {
        wastageQuantity: [
          {
            required: true,
            message: '请输入损耗数量',
            triggger: 'blur'
          }
        ],
        wastageReason: [
          {
            required: true,
            message: '请输入损耗原因'
          }
        ]
      }
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.handleLoading = true
          const submitData = JSON.parse(JSON.stringify(this.tableForm))
          submitData.wastageBatch = submitData.wastageBatch.map(sku => ({
            availableQuantity: sku.available,
            liableSupplyId: sku.supplyId,
            liableSupplyName: sku.supplyName,
            ...sku
          }))

          OemInventory.wastageCreate(submitData).then(res => {
            if (res.success) {
              this.$message.success('底版报损提交成功！')
              this.$emit('submited')
              this.close()
            }
          }).finally(() => {
            this.handleLoading = false
          })
        }
      })
    },
    close () {
      this.$emit('update:show', false)
    },
    closed () {
      this.$refs.form.resetFields()
      this.tableForm.wastageBatch = []
    }
  },
  watch: {
    show (bool) {
      if (bool) {
        const _row = JSON.parse(JSON.stringify(this.row))
        _row['wastageQuantity'] = '' // extra key
        this.tableForm.wastageBatch = [_row]
      }
    }
  }
}
</script>
