<template>
  <div class="pending-delivery">
    <SlListView
      @gotoPage="gotoPage"
      :total="page.total"
      :pageIndex="page.pageIndex"
      :pageSize="page.pageSize"
    >
      <div solt="search">
        <SlSearchForm
          v-model="query"
          :items="searchItems"
          :loading="tableLoading"
          @reset="gotoPage(page.pageSize)"
          @search="gotoPage(page.pageSize)"
        ></SlSearchForm>

        <SlTableToolbar>
          <SlButton type="primary" boxShadow="primary" @click="onExport">导出</SlButton>
        </SlTableToolbar>

        <SlTableInfo :tableData="tableData" :columns="columns" headerAlign="left">
          <template #operation="{row}">
            <el-button class="operation-btn" type="text" @click="onWastage(row)">报损</el-button>
          </template>
        </SlTableInfo>
      </div>
    </SlListView>
    <!-- 创建损耗 -->
    <InventoryDialog :row="row" :show.sync="visible" @submited="gotoPage" />
  </div>
</template>

<script>
import OemInventory from '@api/oemInventory'
import URL from '@api/oemInventory/url'
import { exportFileFromRemote, date } from '@shared/util'
import InventoryDialog from './stockInventory/inventoryDialog.vue'

export default {
  name: 'StockInventory',
  components: { InventoryDialog },
  data () {
    return {
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      query: {},
      searchItems: [
        {
          type: 'input',
          label: '底版SKU',
          name: 'skuCode'
        }
      ],
      tableLoading: false,
      tableData: [],
      columns: [
        {
          name: 'skuCode',
          label: '底版SKU'
        }, {
          name: 'skuImage',
          label: '图片',
          isImage: true,
          data: {
            imgSize: '6rem'
          }
        }, {
          name: 'productName',
          label: '商品名称',
          render: (h, { row }) => {
            return (
              <div>
                <p>{row.productName}</p>
                <p class="color-text--minor">{row.attributes}</p>
              </div>
            )
          }
        }, {
          name: 'available',
          label: '可用数量'
        }, {
          name: 'reserved',
          label: '预留数量'
        }, {
          name: 'locking',
          label: '锁定库存'
        }, {
          name: 'total',
          label: '总量'
        }, {
          name: 'operation',
          label: '操作'
        }
      ],
      // dialog
      visible: false,
      row: null
    }
  },
  methods: {
    onWastage (row) {
      if (row.available <= 0) {
        this.$message.warning('库存可用数量不足，无法进行报损操作！')
        return
      }
      this.row = row
      this.visible = true
    },
    onExport () {
      exportFileFromRemote({
        url: URL.inventoryExport,
        params: this.query,
        name: `底版库存_${date(+new Date(), 'yyyy-MM-dd')}.xlsx`,
        beforeLoad: () => {
          this.$store.dispatch('OPEN_LOADING', { isCount: false, loadingText: '导出中' })
        },
        afterLoad: () => {
          this.$store.dispatch('CLOSE_LOADING')
        }
      })
    },
    gotoPage (pageSize = 10, pageIndex = 1) {
      const params = this.generateParams(pageSize, pageIndex)
      this.tableLoading = true
      OemInventory.inventoryPage(params).then(res => {
        if (res.success) {
          const data = res.data || {}
          this.tableData = data.list
          this.page.total = data.total
          this.page.pageIndex = pageIndex
          this.page.pageSize = pageSize
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    generateParams (pageSize, pageIndex) {
      return {
        ...this.query,
        pageIndex,
        pageSize
      }
    }
  }
}
</script>
